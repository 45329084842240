const catalogPath = '/catalog';

export const backendRoutes = {
  category: {
    path: '/category',
    query: {
      search: 'search',
      awaitLog: 'awaitLog',
    },
  },
  categoriesBatch: {
    path: '/categories-batch',
  },
  inflections: {
    path: `${catalogPath}/inflections`,
  },
  menuCatalog: {
    path: `${catalogPath}/menu`,
  },
  itemsCatalog: {
    path: `${catalogPath}/items`,
  },
  categoryAutoComplete: {
    path: '/category-auto-complete',
    query: {
      search: 'search',
    },
  },
  usersSearchesItems: {
    path: '/users-searches-items',
  },
  itemsCategoriesMatches: {
    path: '/items-categories-matches',
  },
  itemsCategoriesUpdates: {
    path: '/items-categories-updates',
  },
  contact: {
    path: '/contact',
  },
};
