import { ItemDefinition, PatchRequestBody } from '@azure/cosmos';
import { getContainer } from './container';
import { escapeItem, escapeSpecialCharacters, unEscapeItem } from './escaping';

export async function createItem<T extends ItemDefinition>({
  endpoint,
  databaseId,
  containerId,
  item,
}: {
  endpoint: string;
  databaseId: string;
  containerId: string;
  item: T;
}): Promise<T> {
  const escapedItem = escapeItem(item);
  const container = getContainer({ endpoint, databaseId, containerId });
  const { resource } = await container.items.create<T>(escapedItem);

  if (!resource) {
    throw new Error('Failed to upsert item');
  }

  const unEscapedItem = unEscapeItem(resource);
  return unEscapedItem;
}

export async function upsertItem<T extends ItemDefinition>({
  endpoint,
  databaseId,
  containerId,
  item,
}: {
  endpoint: string;
  databaseId: string;
  containerId: string;
  item: T;
}): Promise<T> {
  const escapedItem = escapeItem(item);
  const container = getContainer({ endpoint, databaseId, containerId });
  const { resource } = await container.items.upsert<T>(escapedItem);

  if (!resource) {
    throw new Error('Failed to upsert item');
  }

  const unEscapedItem = unEscapeItem(resource);
  return unEscapedItem;
}

export async function getItem<T extends ItemDefinition>({
  endpoint,
  databaseId,
  containerId,
  itemId,
  partitionKeyValue,
}: {
  endpoint: string;
  databaseId: string;
  containerId: string;
  itemId: string;
  partitionKeyValue: string;
}): Promise<T> {
  const escapedItemId = escapeSpecialCharacters(itemId);
  const escapedPartitionKeyValue =
    partitionKeyValue && escapeSpecialCharacters(partitionKeyValue);

  const container = getContainer({ endpoint, databaseId, containerId });

  const { resource } = await container
    .item(escapedItemId, escapedPartitionKeyValue)
    .read<T>();

  if (!resource) {
    throw new Error(`Item with id ${itemId} not found`);
  }

  const unEscapedItem = unEscapeItem(resource);

  return unEscapedItem;
}

export async function getItems<T extends ItemDefinition>({
  endpoint,
  databaseId,
  containerId,
}: {
  endpoint: string;
  databaseId: string;
  containerId: string;
}): Promise<T[]> {
  const container = getContainer({ endpoint, databaseId, containerId });
  const { resources } = await container.items.readAll<T>().fetchAll();
  const items = resources.map(unEscapeItem);
  return items;
}

export async function patchItem<T extends ItemDefinition>({
  endpoint,
  databaseId,
  containerId,
  itemId,
  partitionKeyValue,
  patchRequestBody,
}: {
  endpoint: string;
  databaseId: string;
  containerId: string;
  itemId: string;
  partitionKeyValue: string;
  patchRequestBody: PatchRequestBody;
}): Promise<T> {
  const escapedItemId = escapeSpecialCharacters(itemId);
  const escapedPartitionKeyValue = escapeSpecialCharacters(partitionKeyValue);
  const container = getContainer({ endpoint, databaseId, containerId });
  const { resource } = await container
    .item(escapedItemId, escapedPartitionKeyValue)
    .patch(patchRequestBody);
  const unEscapedItem = unEscapeItem(resource);
  return unEscapedItem;
}

export async function deleteItem({
  endpoint,
  databaseId,
  containerId,
  itemId,
  partitionKeyValue,
}: {
  endpoint: string;
  databaseId: string;
  containerId: string;
  itemId: string;
  partitionKeyValue?: string;
}): Promise<void> {
  const escapedItemId = escapeSpecialCharacters(itemId);
  const escapedPartitionKeyValue =
    partitionKeyValue && escapeSpecialCharacters(partitionKeyValue);
  const container = getContainer({ endpoint, databaseId, containerId });
  await container.item(escapedItemId, escapedPartitionKeyValue).delete();
}
